import showdown from 'showdown'

const addCloudinaryUrlParams = (url, param) => {
  let newURL
  const splitURL = url.split('upload/')

  newURL = `${splitURL[0]}upload/${param}/${splitURL[1]}`

  return newURL
}

const taskListEnablerExtension = () => [{
  type: 'output',
  regex: /<input type="checkbox" disabled(="")?/g,
  replace: '<input type="checkbox"'
}]

const markdownToHtml = md => {
  const converter = new showdown.Converter()
  converter.setOption('tables', true)
  converter.setOption('backslashEscapesHTMLTags', true)
  converter.setOption('tasklists', true)
  converter.useExtension(taskListEnablerExtension)

  return converter.makeHtml(md)
}

const buildQuery = query => {
  let queryString = ''

  Object.keys(query).forEach((k, i) => {
    if (i === 0) {
      queryString = `?${k}=${query[k]}`
    } else {
      queryString = queryString + `&${k}=${query[k]}`
    }
  })

  return queryString
}

const sortItemsByOrder = items => items.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

const apiResponseItemsFilter = (newItems, oldItems) => {
  const filtered = oldItems

  newItems.forEach(p => {
    const i = filtered.findIndex(item => item.id === p.id)

    if (i !== -1) {
      filtered.splice(i, 1)
    }

    filtered.push(p)
  })

  return filtered
}

const truncate = (value, num = 21, type = 'string') => {
  let valueToTruncate = value

  if (type === 'word') {
    valueToTruncate = valueToTruncate.split(' ')
  }

  if (valueToTruncate.length > num) {
    let trunc

    if (type === 'word') {
      trunc = valueToTruncate.splice(0, num).join(' ')
    } else {
      trunc = valueToTruncate.slice(0, num)
    }

    return `${trunc}...`
  }

  return value
}

const getDataByEnv = (field, source) => {
  return source[field][process.env.NODE_ENV] || source[field]['production']
}

export {
  addCloudinaryUrlParams,
  markdownToHtml,
  buildQuery,
  sortItemsByOrder,
  apiResponseItemsFilter,
  truncate,
  getDataByEnv
}
