import useAuth from './useAuth'
import useTenant from './useTenant'
import { fetchWithAuth } from './api';
import { reactive } from 'vue'

const assetContents = reactive({})

const getUrl = (path) => {
  return `user-assets/${path}`
}

const uploadAndGet = (formData) => {
  return upload(formData).then((res) => {
    return Promise.all(
      res.map(async (asset) => {
        return {
          ...asset,
          url: getPath(asset.uuid),
        }
      }),
    )
  })
}

const upload = (formData) => {
  const { tenant } = useTenant()

  return fetchWithAuth(getUrl(`${tenant.value.namespace}/files`), {
    method: 'POST',
    body: formData,
    headers: {},
  }
  ).then((res) => res.json())
}

const getAll = () => {
  const { tenant } = useTenant()

  return fetchWithAuth(getUrl(`${tenant.value.namespace}/files`)).then((res) => res.json())
}

const getByIds = (ids) => {
  return getAll().then((assets) =>
    assets.filter((asset) => ids.includes(asset.uuid)),
  )
}

const get = async (uuid) => {
  const { tenant } = useTenant()

  if (!assetContents[uuid]) {
    assetContents[uuid] = await fetchWithAuth(
      getUrl(`${tenant.value.namespace}/files/${uuid}`)
    ).then((res) => res.blob())
  }

  return assetContents[uuid]
}

const getPath = (uuid) => {
  const { tenant } = useTenant()
  const { token } = useAuth()

  return getUrl(`${tenant.value.namespace}/files/${uuid}?token=${token.value}`)
}

export default () => ({
  uploadAndGet,
  upload,
  get,
  getPath,
  getAll,
  getByIds,
})
