<template>
  <Card
    v-if="file"
    class="c-rrc-file"
    :title="file.name"
    :description="file.description"
    :image-id="file.cover"
    :item="file"
    :actions="cardActions"
  />
</template>

<script>
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import useStructuresLocal from '@/composables/useStructures'
import useContentsLocal from '@/composables/useContents'
import useTranslations from '@/composables/useTranslations'
import Card from '@/components/Card'
import { useStructures, useContents, useAssets, useLocalizations } from '@/core'

export default {
  name: 'RRCFiles',

  components: {
    Card
  },

  props: {
    data: Object
  },

  setup(props) {
    const { isFallbackLanguage, gqlContentQuery } = useTranslations()
    const { locale } = useI18n()
    const { fetchStructuresNode, getStructuresById } = useStructures()
    const { fetchStructuresChildren } = useStructures()
    const { getStructuresChildrens } = useStructuresLocal()
    const { fetchStructuresLocalization } = useLocalizations()
    const { fetchObject } = useContents()
    const { getObjectById } = useContentsLocal()
    const { fetchAsset } = useAssets()
    const file = computed(() => getStructuresById(props.data.value, false, 'DIRECTORY', locale.value))
    const cardActions = {
      type: 'file',
      action: async item => {
        await fetchStructuresChildren(item.slugPath, {limit: 100, filter: `{"type": "OBJECT"}`})
        getStructuresChildrens(item.id, 'OBJECT').forEach(async sc => {
          if (sc.contentId) {
            if (isFallbackLanguage.value) {
              await fetchObject(sc.contentId)
            } else {
              await gqlContentQuery(sc.contentId)
            }
            const obj = getObjectById(sc.contentId, locale.value)
            fetchAsset(obj.content.assetId)
          }
        })
      }
    }
    onMounted(async () => {
      if (!file.value) {
        await fetchStructuresNode(props.data.value)

        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(props.data.value, locale.value)
        }
      }
    })

    return {
      file,
      cardActions
    }
  }
}
</script>