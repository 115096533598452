import { addCheck, isChecked, removeCheck } from '../services/checklist'

const supportCheckList = el => {
  el.querySelectorAll('.task-list-item').forEach(async (tli, i) => {
    const itemId = `${el.dataset.elementId}-${i}`

    tli.id = itemId
    if (await isChecked(itemId)) {
      tli.querySelector('input').checked = true
    }
    tli.addEventListener('click', async () => {
      if (await isChecked(itemId)) {
        await removeCheck(itemId)
      } else {
        await addCheck(itemId)
      }
    })
  })
}

export {
  supportCheckList
}
