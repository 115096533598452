import useLocalizations from '@/core/useLocalizations'
import useContents from '@/core/useContents'
import { merge } from 'lodash'

const { getLocalizationById } = useLocalizations()
const { objects } = useContents()

const getObjectById = (contentId, locale = '') => {
  let obj = objects.value.filter(obj => obj.id === contentId)[0]

  if (obj && locale) {
    obj = JSON.parse(JSON.stringify(obj))
    obj.content = merge({}, obj.content, getLocalizationById(contentId, locale, 'content')?.content)
  }

  return obj
}

export default () => {
  return {
    objects,
    getObjectById,
  }
}
