const queryContent = `
query queryContent($objectId: String!) {
  content(objectId: $objectId) {
    data {
      id
      namespace
      content
    }
    error {
      message
      status
    }
  }
}
`
const queryContentWithLocale = `
query queryContentWithLocale($objectId: String!, $locale: String!) {
  content(objectId: $objectId) {
    data {
      id
      namespace
      content
      localization(locale: $locale)
    }
    error {
      message
      status
    }
  }
}
`

const queryStructureWithLocale = `
query queryStructureWithLocale($id: String!, $locale: String!) {
  structure(id: $id) {
    data {
      id
      namespace
      name
      description
      cover
      parent
      type
      order
      slug
      contentType
      contentId
      localization(locale: $locale) {
        data {
          service
          locale
          content
        }
        error {
          message
          status
        }
      }
    }
    error {
      message
      status
    }
  }
}
`

const queryStructureChildrenWithLocale = `
query queryStructureWithLocale($id: String!, $locale: String!, $limit: Int!, $page: Int!) {
  structure(id: $id) {
    data {
      children(limit: $limit, page: $page) {
        data {
          id
          namespace
          name
          description
          cover
          parent
          type
          slug
          order
          contentType
          contentId
          localization(locale: $locale) {
            data {
              locale
              content
              service
              id
            }
          }
        }
        pagination {
          page
          total
          limit
        }
      }
    }
    error {
      message
      status
    }
  }
}
`
const querySingleContent = `
query querySingleContent($id: String!, $limit: Int!, $page: Int!) {
  structure(id: $id) {
    data {
      children(limit: $limit, page: $page) {
        data {
          id
          namespace
          name
          description
          cover
          parent
          type
          slug
          order
          contentType
          contentId
          content {
            data {
              id
              namespace
              schemaId
              content
            }
            error {
              message
              status
            }
          }
        }
        pagination {
          page
          total
          limit
        }
      }
    }
    error {
      message
      status
    }
  }
}
`
const querySingleContentWithLocale = `
query queryStructureWithLocale($id: String!, $locale: String!, $limit: Int!, $page: Int!) {
  structure(id: $id) {
    data {
      children(limit: $limit, page: $page) {
        data {
          id
          namespace
          name
          description
          cover
          parent
          type
          slug
          order
          contentType
          contentId
          content {
            data {
              id
              namespace
              schemaId
              content
              localization(locale: $locale)
            }
            error {
              message
              status
            }
          }
          localization(locale: $locale) {
            data {
              locale
              content
              service
              id
            }
          }
        }
        pagination {
          page
          total
          limit
        }
      }
    }
    error {
      message
      status
    }
  }
}
`

export {
  queryContent,
  queryContentWithLocale,
  queryStructureWithLocale,
  queryStructureChildrenWithLocale,
  querySingleContent,
  querySingleContentWithLocale,
}
