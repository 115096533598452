import { ref } from 'vue'
import { useAssets } from '@/core'

const { fetchAsset, getAssetById } = useAssets()

const playPlayer = ref(false)
const audioContent = ref(null)
const audioPlayed = ref(null)

const media = new Audio()

const onPlayAudio = async (audioId) => {
  if (audioPlayed.value !== audioId) {
    await fetchAsset(audioId)
    audioContent.value = getAssetById(audioId)
    audioPlayed.value = audioId
    media.src = audioContent.value.url
  }
  media.play()
  playPlayer.value = true
}

const onPauseAudio = () => {
  playPlayer.value = false
  media.pause()
}

export default () => {
  return {
    playPlayer,
    onPlayAudio,
    onPauseAudio,
    audioPlayed,
    audioContent,
  }
}
