import { computed } from 'vue'
import { useTenant, useLocalizations, useContents, useStructures } from '@/core'
import i18n from '@/i18n'
import {
  queryContentWithLocale,
  queryStructureWithLocale,
  queryStructureChildrenWithLocale,
  querySingleContent,
  querySingleContentWithLocale,
} from '@/graphql'
import { postGql } from '@/core/services/graphql'

const { tenant } = useTenant()
const { setObject } = useContents()
const { setStructure } = useStructures()
const { setLocalization } = useLocalizations()
const isFallbackLanguage = computed(
  () => tenant.value.langs[0].code === i18n.global.locale,
)

const gqlContentQuery = async (contentId, locale = '') => {
  const lang = locale || i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(tenant.value.namespace, {
      query: queryContentWithLocale,
      variables: {
        objectId: contentId,
        locale: lang,
      },
    })
    setObject([gqlRes.data.content.data])
    if (gqlRes.data.content.data.localization) {
      gqlRes.data.content.data.localization.content = {
        content: gqlRes.data.content.data.localization.content,
        assetId: gqlRes.data.content.data.localization.assetId,
      }
      gqlRes.data.content.data.localization.service = 'edtech.content'
      gqlRes.data.content.data.localization.locale = lang
      gqlRes.data.content.data.localization.objectId = contentId
      setLocalization(gqlRes.data.content.data.localization)
    }
  } catch {
    //
  }

  return gqlRes
}

const gqlStructureQuery = async (id, locale = '') => {
  const lang = locale || i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(tenant.value.namespace, {
      query: queryStructureWithLocale,
      variables: {
        id: id,
        locale: lang,
      },
    })
    setStructure([gqlRes.data.content.data])
    if (gqlRes.data.content.data.localization.data) {
      gqlRes.data.content.data.localization.data.objectId =
        gqlRes.data.content.data.id
      setLocalization(gqlRes.data.content.data.localization.data)
    }
  } catch {
    //
  }

  return gqlRes
}

const gqlStructureChildrenQuery = async (
  id,
  locale = '',
  limit = 10,
  page = 0,
) => {
  const lang = locale || i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(tenant.value.namespace, {
      query: queryStructureChildrenWithLocale,
      variables: {
        id,
        locale: lang,
        limit,
        page,
      },
    })

    gqlRes.data.structure.data.children.data.forEach((child) => {
      const childLocalization = child.localization.data
      delete child.localization
      setStructure([child])
      if (childLocalization) {
        childLocalization.objectId = child.id
        setLocalization(childLocalization)
      }
    })
  } catch {
    //
  }

  return gqlRes
}

const gqlSingleContent = async (id, locale = '', limit = 200, page = 0) => {
  const lang = locale || i18n.global.locale
  let gqlRes

  try {
    gqlRes = await postGql(tenant.value.namespace, {
      query:
        (isFallbackLanguage.value && querySingleContent) ||
        querySingleContentWithLocale,
      variables: {
        id,
        locale: lang,
        limit,
        page,
      },
    })

    gqlRes.data.structure.data.children.data.forEach((child) => {
      const contentData = child.content?.data
      const childLocalization = child.localization?.data
      const baseContentDataLocalization = {}
      const contentDataLocalization = contentData?.localization
      setStructure([child])
      if (contentData) {
        setObject([contentData])
        if (contentDataLocalization) {
          baseContentDataLocalization.content = JSON.parse(JSON.stringify(contentDataLocalization))
          baseContentDataLocalization.objectId = contentData.id
          baseContentDataLocalization.service = 'edtech.content'
          baseContentDataLocalization.locale = lang
          setLocalization(baseContentDataLocalization)
        }
      }
      if (childLocalization) {
        childLocalization.objectId = child.id
        setLocalization(childLocalization)
      }
    })
  } catch {
    //
  }

  return gqlRes
}

const getTenantTranslation = (item) => {
  return (
    (item && item[i18n.global.locale]) ||
    (item && item[tenant.value.langs[0].code])
  )
}

export default () => ({
  isFallbackLanguage,
  gqlContentQuery,
  gqlStructureQuery,
  gqlStructureChildrenQuery,
  getTenantTranslation,
  gqlSingleContent,
})
