<template>
  <div class="c-image" :class="{ [`c-image--size-${ imageSize }`]: imageSize }">
    <div v-if="image" class="c-image__wrapper">
      <img ref="imgTag" :src="image.url" :alt="image.altText" class="c-image__image">
    </div>
    <div v-if="data && data.content && data.content.caption" class="c-image__caption">
      {{ data.content.caption }}
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, nextTick } from 'vue'
import mediumZoom from 'medium-zoom'

import { useAssets, utils } from '@/core'

export default {
  name: 'Image',

  props: {
    data: Object
  },

  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const image = ref(null)
    const imageSize = ref(null)
    const imgTag = ref(null)

    const getImageOption = key => {
      let imageOption = null

      if (props.data.content.options) {
        imageOption = props.data.content.options.find(option => option.key == key)
        return imageOption ? imageOption.value : null
      }

      return imageOption
    }

    onBeforeMount(async () => {
      await fetchAsset(props.data.content.assetId)
      image.value = getAssetById(props.data.content.assetId)
      image.value.url = utils.addCloudinaryUrlParams(image.value.url, 'w_1200,c_limit')
      image.value.altText = props.data.content.altText || ''
      imageSize.value = getImageOption('size')

      nextTick(() => {
        mediumZoom(imgTag.value)
      })
    })

    return {
      image,
      imageSize,
      imgTag,
    }
  }
}
</script>
