<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="187" height="54" viewBox="0 0 187 54"
    class="c-dbok-logo">
    <g fill="none" fill-rule="evenodd">
      <g class="c-dbok-logo__group-1" fill="#333">
        <path d="M42.86 27.717h14.282v14.225H42.86z"></path>
        <path fill-rule="nonzero"
          d="M81.49 15.68c-2.472.004-4.911.556-7.14 1.618V.4H63.794v53.148H74.35V51.93a16.622 16.622 0 0 0 7.14 1.618c10.13-.375 18.045-8.843 17.697-18.934.35-10.09-7.567-18.56-17.697-18.933zm0 26.576c-4.088-.151-7.282-3.568-7.14-7.64-.143-4.071 3.051-7.49 7.14-7.64 4.086.15 7.28 3.567 7.139 7.638.142 4.073-3.052 7.491-7.14 7.642zM25.658.4v16.898a16.653 16.653 0 0 0-7.14-1.617C8.388 16.055.472 24.524.822 34.614c-.35 10.091 7.566 18.56 17.696 18.934a16.648 16.648 0 0 0 7.14-1.618v1.618h10.554V.4H25.658zm-7.14 41.856c-4.089-.15-7.284-3.569-7.142-7.642-.141-4.07 3.053-7.488 7.14-7.639 4.087.151 7.28 3.568 7.14 7.64.14 4.07-3.051 7.487-7.138 7.641z">
        </path>
        <path
          d="M134.195 53.548l-9.284-15.413-9.774 15.413zM161.407 53.55V40.264h7.14c4.087.151 7.28 3.568 7.14 7.64v5.646h10.554v-5.736a19.561 19.561 0 0 0-5.092-13.2 19.559 19.559 0 0 0 5.094-13.286 20.104 20.104 0 0 0-.802-5.647h-12.099a7.858 7.858 0 0 1 2.345 5.647c.142 4.072-3.052 7.49-7.14 7.642h-7.14V.41h-10.554v53.148l10.554-.008z">
        </path>
      </g>
      <g class="c-dbok-logo__group-2" fill="#F15A29">
        <path
          d="M112.74 48.647l5.937-9.36a6.895 6.895 0 0 1-.884-3.36c.001-3.927 3.197-7.11 7.14-7.111a7.154 7.154 0 0 1 5.051 2.081 7.097 7.097 0 0 1 2.093 5.03 6.956 6.956 0 0 1-.687 2.988l5.82 9.66a17.486 17.486 0 0 0 5.421-12.65c0-9.735-7.923-17.626-17.696-17.626a17.732 17.732 0 0 0-12.514 5.163 17.59 17.59 0 0 0-5.182 12.465 17.513 17.513 0 0 0 5.5 12.72z">
        </path>
      </g>
    </g>
  </svg>
</template>