import useAuth from '../useAuth'

const beforeEach = (to, from, next) => {
  const auth = useAuth()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth.instance().isAuthenticated() && auth.token.value && typeof auth.token.value !== 'undefined') {
      auth.setToken()
      auth.setUser()

      next()
    } else {
      auth.instance().checkToken()
        .then(() => {
          auth.setToken()
          auth.setUser()

          next()
        })
        .catch(() => {
          next({path: '/login'})
        })
    }
  } else {
    next()
  }
}

export {
  beforeEach
}
