<template>
  <div class="c-dbok-menu">
    <a
      v-if="dbok.length === 1"
      :href="dbok[0].link"
      class="c-dbok-menu__link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <DBokLogo class="c-dbok-menu__logo" />
    </a>
    <TheButton
      v-else
      class="c-dbok-menu__btn"
      @click="toggleMenu = !toggleMenu"
    >
      <DBokLogo class="c-dbok-menu__logo" />
      <span
        class="c-dbok-menu__caret"
        :class="{ 'c-dbok-menu__caret--opened': toggleMenu }"
      ></span>
    </TheButton>
    <ul v-if="toggleMenu" class="c-topbar__dbok-list">
      <li
        v-for="dLink in dbok"
        :key="dLink.link"
        class="c-topbar__dbok-list-item"
      >
        <a
          :href="dLink.link"
          class="c-topbar__dbok-list-item-link"
          target="_blank"
          rel="noopener noreferrer"
          @click="toggleMenu = !toggleMenu"
          >{{ dLink.title }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import TheButton from '@/components/TheButton'
import DBokLogo from '@/components/DBokLogo'

export default defineComponent({
  name: 'DBokMenu',

  components: { TheButton, DBokLogo },

  props: {
    dbok: [String, Array],
  },

  setup() {
    const toggleMenu = ref(false)

    return {
      toggleMenu,
    }
  },
})
</script>
