<template>
  <div v-if="isModalOpen" class="c-modal" @click.self="closeModal">
    <div class="c-modal__wrapper">
      <div class="c-modal__inner">
        <div class="c-modal__top">
          <div class="c-modal__top-wrapper">
            <TheHeading class="c-modal__top-heading">
              {{ modalContent.content.name }}
            </TheHeading>
            <TheButton class="c-modal__top-close" text @click="closeModal" />
          </div>
        </div>
        <div class="c-modal__content">
          <div class="c-modal__content-wrapper" v-html="modalContent.content.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'

export default {
  components: {
    TheHeading,
    TheButton,
  },

  setup() {
    const { isModalOpen, modalContent, closeModal } = useModal()

    return {
      isModalOpen,
      closeModal,
      modalContent,
    }
  },
}
</script>
