import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import config from '@/config'
import mdHtml from '@/directives/mdHtml'
import ContentRenderer from '@/components/ContentRenderer'

const head = createHead()
const bootstrapApp = async () => {
  const tenantConfig = await config()

  createApp(App, {
    tenantConfig
  })
  .use(head)
  .use(store)
  .use(router)
  .use(i18n)
  .directive('md-html', mdHtml)
  .component('ContentRenderer', ContentRenderer)
  .mount('#app')
}

bootstrapApp()
