<template>
  <div
    class="c-card"
    :class="[
      `c-card--${actions.type}`,
      typeLabel && `c-card--${typeLabel}`,
      { 'c-card--has-link': hasLink },
    ]"
  >
    <component
      :is="cardWrapperTag"
      class="c-card__wrapper"
      v-bind="cardWrapperAttrs"
      v-on="cardWrapperEvents"
      :tabindex="isModalOpen ? '-1' : '0'"
    >
      <div class="c-card__content">
        <div v-if="image" class="c-card__image">
          <img class="c-card__image-image" :src="image.url" :alt="title" />
        </div>
        <div class="c-card__text">
          <h3 v-if="title" class="c-card__title">
            {{ title }}
          </h3>
          <div
            v-if="description"
            class="c-card__description"
            v-md-html="shorten(description, 150)"
          />
          <div v-if="hasTypeLabel" class="c-card__type-label">
            {{ translatedTypeLabel.toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="c-card__actions">
        <div v-if="hasDownloads" class="c-card__actions-left">
          <div class="c-card__actions-label">{{ $t('CARD_DOWNLOAD') }}:</div>
          <div class="c-card__actions-downloads">
            <a
            v-for="file in files"
            :key="file.id"
            href="#"
            class="c-card__actions-download"
            @click.prevent="downloadFile(file)"
            >{{ file.fileType }}</a>
          </div>
        </div>
        <div v-if="hasButton" class="c-card__actions-right">
          <TheButton class="c-card__btn" :tabindex="hasLink ? '-1' : '0'">
            {{ $t('CARD_SEE_MORE') }}
          </TheButton>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { ref, computed, defineComponent, onMounted } from 'vue'
import { saveAs } from 'file-saver'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton'
import { utils, useAssets } from '@/core'
import useStructuresLocal from '@/composables/useStructures'
import useContentsLocal from '@/composables/useContents'
import { truncate } from '@/core/utils'
import useModal from '@/composables/useModal'

export default defineComponent({
  name: 'Card',

  components: {
    TheButton,
  },

  props: {
    title: String,
    description: String,
    imageId: String,
    actions: Object,
    item: Object,
  },

  setup(props) {
    const image = ref(null)
    const { locale, t } = useI18n()
    const { fetchAsset, getAssetById } = useAssets()
    const { getStructuresChildrens } = useStructuresLocal()
    const { getObjectById } = useContentsLocal()
    const { addCloudinaryUrlParams } = utils
    const { isModalOpen } = useModal()
    const cardWrapperTag = computed(() => {
      if (props.actions.type === 'link' || props.actions.type === 'media') {
        return 'router-link'
      }

      if (props.actions.type === 'concept') {
        return 'a'
      }

      return 'div'
    })
    const cardWrapperAttrs = computed(() => {
      if (props.actions.type === 'link' || props.actions.type === 'media') {
        return {
          to: props.actions.action(props.item),
        }
      }

      if (props.actions.type === 'concept') {
        return {
          href: '#',
        }
      }

      return {}
    })
    const cardWrapperEvents = computed(() => {
      if (props.actions.type === 'concept') {
        return {
          click: (ev) => {
            ev.preventDefault()
            props.actions.action(props.item)
          },
        }
      }

      return {}
    })
    const typeLabel = computed(() => {
      let types = []

      if (props.actions.type === 'media') {
        types = ['video', 'audio', 'vr', 'ar', 'sim', 'anime']

        return (
          types.filter((type) => props.item.slug.lastIndexOf(type) > 0)[0] ||
          'video'
        )
      }

      return ''
    })
    const translatedTypeLabel = computed(() =>
      t(`CARD_LABEL_${typeLabel.value.toUpperCase()}`),
    )
    const hasLink = computed(() => {
      const allowedTypes = ['link', 'concept', 'media']

      return allowedTypes.some((at) => at === props.actions.type)
    })
    const hasButton = computed(() => {
      const allowedTypes = ['link', 'concept']

      return allowedTypes.some((at) => at === props.actions.type)
    })
    const hasDownloads = computed(() => {
      const allowedTypes = ['file']

      return allowedTypes.some((at) => at === props.actions.type)
    })
    const hasTypeLabel = computed(() => {
      const allowedTypes = ['media']

      return allowedTypes.some((at) => at === props.actions.type)
    })
    const downloadFile = async (file) => {
      const fileExternal = await fetch(file.url)
      const blob = await fileExternal.blob()

      saveAs(blob, file.filename)
    }

    const shorten = (text, limit) => {
      return truncate(text, limit)
    }
    const files = computed(() => {
      if (props.actions.type === 'file') {
        const arr = []

        getStructuresChildrens(props.item.id, 'OBJECT').forEach((sc) => {
          const obj = getObjectById(sc.contentId, locale.value)

          if (!obj) return []
          const file = getAssetById(obj.content.assetId)
          if (file) {
            file.fileType = obj.content.name
            arr.push(file)
          }
        })

        return arr
      }

      return []
    })

    onMounted(async () => {
      if (props.imageId) {
        await fetchAsset(props.imageId)
        image.value = getAssetById(props.imageId)
        image.value.url = addCloudinaryUrlParams(image.value.url, 'w_400')
      }

      if (props.actions.type === 'file') {
        props.actions.action(props.item)
      }
    })

    return {
      image,
      shorten,
      cardWrapperTag,
      cardWrapperAttrs,
      typeLabel,
      hasButton,
      hasDownloads,
      hasLink,
      hasTypeLabel,
      cardWrapperEvents,
      files,
      downloadFile,
      translatedTypeLabel,
      isModalOpen,
    }
  },
})
</script>
