<template>
  <Card
    v-if="concept"
    class="c-rrc-concept"
    :title="concept.name"
    :description="concept.description"
    :image-id="concept.cover"
    :item="concept"
    :actions="cardActions"
  />
</template>

<script>
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import useTranslations from '@/composables/useTranslations'
import useModal from '@/composables/useModal'
import Card from '@/components/Card'
import { useStructures, useLocalizations } from '@/core'

export default {
  name: 'RRCConcept',

  components: {
    Card,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { isFallbackLanguage } = useTranslations()
    const { locale } = useI18n()
    const { setModal } = useModal()
    const { fetchStructuresNode, getStructuresById } = useStructures()
    const { fetchStructuresLocalization } = useLocalizations()
    const concept = computed(() =>
      getStructuresById(props.data.value, false, 'DIRECTORY', locale.value),
    )
    const cardActions = {
      type: 'concept',
      action: (concept) => {
        onShowConcept(concept)
      },
    }
    const onShowConcept = (concept) => {
      setModal('ModalConcept', {
        title: concept.name,
        content: concept,
        type: 'concept',
      })
    }
    onMounted(async () => {
      if (!concept.value) {
        await fetchStructuresNode(props.data.value)

        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(props.data.value, locale.value)
        }
      }
    })

    return {
      concept,
      cardActions,
      onShowConcept,
    }
  },
}
</script>
