<template>
  <Card
    v-if="article && parent && topParent"
    class="c-rrc-article"
    :title="article.name"
    :description="article.description"
    :item="article"
    :actions="cardActions"
  />
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useTranslations from '@/composables/useTranslations'
import Card from '@/components/Card'
import { useStructures, useLocalizations } from '@/core'

export default {
  name: 'RRCArticle',

  components: {
    Card,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { locale } = useI18n()
    const { isFallbackLanguage } = useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()
    const { fetchStructuresNode, getStructuresById } = useStructures()
    const article = computed(() => getStructuresById(props.data.value, false, 'DIRECTORY', locale.value))
    const parent = ref(null)
    const topParent = ref(null)
    const cardActions = {
      type: 'link',
      action: () => {
        return `/articles/${topParent.value.slug}/${parent.value.slug}/${article.value.slug}`
      }
    }

    onMounted(async () => {
      if (!article.value) {
        await fetchStructuresNode(props.data.value)

        try {
          if (!isFallbackLanguage.value) {
            await fetchStructuresLocalization(props.data.value, locale.value)
          }
        } catch {
          //
        }
      }
      const articleInfo = getStructuresById(props.data.value, locale.value)
      
      //fetch parent category
      await fetchStructuresNode(articleInfo.parent)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(articleInfo.parent, locale.value)
        }
      } catch {
        //
      }
      parent.value = getStructuresById(articleInfo.parent, locale.value)
      
      //fetch top catalog
      await fetchStructuresNode(parent.value.parent)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(parent.value.parent, locale.value)
        }
      } catch {
        //
      }
      topParent.value = getStructuresById(parent.value.parent, locale.value)
    })

    return {
      article,
      parent,
      topParent,
      cardActions,
    }
  },
}
</script>
