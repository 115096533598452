const nn = {
  LOGIN_STUDENT: 'Elev',
  LOGIN_TEACHER: 'Lærar',
  TOPBAR_LOGOUT: 'Logg ut',
  TOPBAR_LOGO_ALT: 'Heim',
  TOPBAR_DBOK_LOGO_ALT: 'd-bok',
  LANGUAGE_SELECT_LABEL: 'Språk',
  TABLE_OF_CONTENT: '​Innhold',
  CARD_SEE_MORE: 'Sjå meir',
  CARD_DOWNLOAD: 'Last ned',
  CARD_VIDEO_MINUTES: 'minutter',
  CARD_LABEL_VIDEO: 'video',
  CARD_LABEL_AUDIO: 'audio',
  CARD_LABEL_VR: 'vr',
  CARD_LABEL_AR: 'ar',
  CARD_LABEL_SIM: 'sim',
  CARD_LABEL_ANIME: 'animasjon',
  NAV_HOME: 'Heim',
  NAV_DOCTOOL: 'Dokumentasjonsverktøy',
  NAV_NO_ACCESS: 'Ikke tilgang til dette produktet',
  NAV_NO_ACCESS_DESCRIPTION: '<p>Du har ikke tilgang til dette produktet.</p><a href="https://fagbokforlaget.no/Bransjeteknikk-Vg2_truck-og-l%C3%B8fteredskap,-nettressurs/I9788211046727" target="_blank">Les mer her.</a>',
  MODAL_SEE_ALSO: 'Sjå også',
  HAMBURGER_MENU: 'Meny',
  CONCEPTS_SEARCH_NO_RESULTS: 'Ingen resultater',
  CONCEPTS_SEARCH_LOADING: 'Lastar',
  SEARCH: 'Søk',
  SEARCH_PLACEHOLDER: 'Skriv her…',
  SEARCH_FORM: 'Søk form',
  SEARCH_INPUT: 'Search field',
  RRC_CONCEPT: 'Fagomgrep',
  RRC_FILES: 'Dokumentasjon',
  RRC_LINK: 'Lover, forskrifter og datablader',
  RRC_VIDEO: 'Multimedium',
  RRC_ARTICLE: 'Artiklar',
  RRC_TEXT: 'Annen informasjon',
  CONCEPTS_LANG_LABEL: 'Oversetting',
  CONCEPTS_LANG_PLACEHOLDER: 'Ingen',
}

export default nn
