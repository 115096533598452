<template>
  <Card
    v-if="video && parent"
    class="c-rrc-video"
    :title="video.name"
    :description="video.description"
    :image-id="video.cover"
    :item="video"
    :actions="cardActions"
  />
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useTranslations from '@/composables/useTranslations'
import Card from '@/components/Card'
import { useStructures, useLocalizations } from '@/core'

export default {
  name: 'RRCVideo',

  components: {
    Card,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { locale } = useI18n()
    const { isFallbackLanguage } = useTranslations()
    const { fetchStructuresLocalization } = useLocalizations()
    const { fetchStructuresNode, getStructuresById } = useStructures()
    const video = computed(() => getStructuresById(props.data.value, false, 'DIRECTORY', locale.value))
    const parent = ref(null)
    const cardActions = {
      type: 'media',
      action: () => {
        const slug = `/media/multimedia/${parent.value.slug}/${video.value.slug}`
        return slug
      },
    }

    onMounted(async () => {
      if (!video.value) {
        await fetchStructuresNode(props.data.value)

        try {
          if (!isFallbackLanguage.value) {
            await fetchStructuresLocalization(props.data.value, locale.value)
          }
        } catch {
          //
        }
      }
      const videoInfo = getStructuresById(props.data.value, locale.value)
      await fetchStructuresNode(videoInfo.parent)
      try {
        if (!isFallbackLanguage.value) {
          await fetchStructuresLocalization(videoInfo.parent, locale.value)
        }
      } catch {
        //
      }
      parent.value = getStructuresById(videoInfo.parent, locale.value)
    })

    return {
      video,
      parent,
      cardActions,
    }
  },
}
</script>
