<template>
  <div v-if="isModalOpen" class="c-modal" @click.self="closeModal">
    <div class="c-modal__wrapper">
      <div class="c-modal__inner">
        <div class="c-modal__top">
          <div class="c-modal__top-wrapper">
            <TheHeading class="c-modal__top-heading">
              {{ modalContent.content.name }}
            </TheHeading>
            <TheButton
              class="c-modal__top-close"
              :tabindex="isModalOpen ? '0' : '-1'"
              text
              @click="closeModal"
            />
          </div>
        </div>
        <div class="c-modal__content">
          <div class="c-modal__content-wrapper">
            <ContentRenderer
              v-if="contentItems.length"
              :content-items="contentItems"
            />
            <div
              :class="[
                'c-modal-concept__translated-content',
                `c-modal-concept__translated-content--${direction}`,
              ]"
              :dir="direction"
            >
              <div
                v-if="conceptLocale && translatedParent?.content?.name"
                class="c-modal-concept__translated-content--name"
              >
                {{ translatedParent.content.name }}
              </div>
              <ContentRenderer
                v-if="conceptLocale && translatedItems.length"
                :content-items="translatedItems"
              />
            </div>
          </div>
          <div v-if="false" class="c-modal__bottom">
            <div class="c-modal__bottom-wrapper">
              <div class="c-modal__bottom-label">
                {{ $t('MODAL_SEE_ALSO') }}
              </div>
              <div class="c-modal__bottom-buttons">
                <TheButton class="c-modal__btn"> spenning </TheButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useContents, useStructures, useLocalizations, useTenant } from '@/core'
import useModal from '@/composables/useModal'
import { useI18n } from 'vue-i18n'
import useStructuresLocal from '@/composables/useStructures'
import useTranslations from '@/composables/useTranslations'
import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'
import ContentRenderer from '@/components/ContentRenderer'

const DIRECTION_RTL = ['ar', 'fa-AF']

export default {
  components: {
    TheHeading,
    TheButton,
    ContentRenderer,
  },

  setup() {
    const { isModalOpen, modalContent, modalContentItems, closeModal } =
      useModal()
    const { fetchObject } = useContents()
    const { getLocalizationById, fetchStructuresLocalization } =
      useLocalizations()
    const { locale } = useI18n()
    const { fetchStructuresChildren } = useStructures()
    const { getStructuresChildrens } = useStructuresLocal()
    const { tenant } = useTenant()
    const { gqlContentQuery, isFallbackLanguage } = useTranslations()

    const conceptLocale = computed(
      () => modalContent.value.options?.translation,
    )

    const contentItems = computed(() =>
      getStructuresChildrens(
        modalContent.value.content.id,
        'OBJECT',
        locale.value,
      ),
    )

    const translatedParent = computed(() =>
      getLocalizationById(
        modalContent.value.content.id,
        conceptLocale.value,
        'structure',
      ),
    )

    const translatedItems = computed(() =>
      getStructuresChildrens(
        modalContent.value.content.id,
        'OBJECT',
        conceptLocale.value,
      ).filter((child) =>
        getLocalizationById(child.contentId, conceptLocale.value, 'content'),
      ),
    )

    const direction = computed(() => {
      if (DIRECTION_RTL.some((drtl) => drtl === conceptLocale.value)) {
        return 'rtl'
      }

      return 'ltr'
    })

    onMounted(async () => {
      if (modalContent.value.content?.id) {
        await fetchStructuresChildren(
          modalContent.value.content.id,
          {
            limit: 100,
            filter: `{"type": "OBJECT"}`,
          },
          {
            namespace: tenant.value.concepts?.namespace,
          },
        )
        contentItems.value.forEach((ci) => {
          if (ci.contentId) {
            if (!conceptLocale.value) {
              if (isFallbackLanguage.value) {
                fetchObject(ci.contentId)
              } else {
                gqlContentQuery(ci.contentId, locale.value)
              }
            } else {
              gqlContentQuery(ci.contentId, conceptLocale.value, {
                namespace: tenant.value.concepts?.namespace,
              }).catch(() => {
                // silence
              })
            }
          }
        })
        if (conceptLocale.value) {
          await fetchStructuresLocalization(
            modalContent.value.content.id,
            conceptLocale.value,
          )
        }
      }
    })

    return {
      isModalOpen,
      closeModal,
      contentItems,
      modalContent,
      modalContentItems,
      getLocalizationById,
      locale,
      direction,
      translatedItems,
      translatedParent,
      conceptLocale,
      tenant,
    }
  },
}
</script>
