import DB from './db'
import useAuth from '../useAuth'

const db = new DB()
const { user } = useAuth()

const addCheck = async id => {
  try {
    await db.createOrUpdate({
      _id: `${user.id}-${id}`,
      name: 'checked',
      value: true
    })
  } catch(err) {
    return null
  }
}

const isChecked = async id => {
  try {
    await db.getData(`${user.id}-${id}`)

    return true
  } catch(err) {
    return false
  }
}

const removeCheck = async id => {
  try {
    await db.removeData(`${user.id}-${id}`)

    return true
  } catch(err) {
    return false
  }
}

export {
  addCheck,
  isChecked,
  removeCheck
}
