<template>
  <div v-if="toc.length" class="c-content-toc">
    <div class="c-content-toc__wrapper" ref="tocWrapper" :class="{ 
      'c-content-toc--scroll': tocScrollVisible,
      'c-content-toc--fixed': tocPositionFixed, 
    }">
      <span class="c-content-toc__title">{{ $t('TABLE_OF_CONTENT') }}</span>
      <TheButton v-if="showScrollBtnUp" class="c-content-toc__nav-btn c-content-toc__nav-btn--up" @click="moveTocScroll('up')"/>
      <nav class="c-content-toc__nav">
        <ul class="c-content-toc__nav-wrapper">
          <li class="c-content-toc__nav-item" v-for="(item, itemId) in toc" :key="item.title">
            <a href="#" rel="nofollow" class="c-content-toc__nav-link" :data-toc-id="`${ item.id }`" @click.prevent="onAnchorScroll(item.id, itemId)">
              <div class="c-content-toc__progress-line">
                <div class="c-content-toc__progress-circle" :class="{ 'c-content-toc__progress-circle--completed' : item.completed }" />
                <span class="c-content-toc__nav-text">{{ item.title }}</span>
              </div>
            </a>
          </li>
        </ul>
      </nav>
      <TheButton v-if="showScrollBtnDown" class="c-content-toc__nav-btn c-content-toc__nav-btn--down" @click="moveTocScroll('down')"/>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import TheButton from '@/components/TheButton'
import { markComplete, isCompleted } from '@/services/tocProgress'

const TOC_SCROLL_OFFSET = 48
const SECTION_TIME_THRESHOLD = 2

export default {
  name: 'ContentToC',

  components: {
    TheButton
  },

  props: {
    parentClass: String
  },

  setup(props) {
    const route = useRoute()
    const articleSlug = route.params.content.pop()
    const toc = ref([])
    let currentSection = ''
    const lastRaf = 0
    const tocBuilder = async raf => {
      if (!lastRaf || raf - lastRaf >= 2 * 1000) {
        const headings = Array.from(document.querySelectorAll(`.${props.parentClass}__wrapper h2`))
        const items = []

        headings.forEach(h => {
          items.push({
            title: h.innerText,
            id: h.id,
          })
        })
        if (toc.value.length != items.length) {
          toc.value = await Promise.all(items.map(async (item) => {
            item.completed = await isCompleted(item.id, articleSlug)

            return item
          }))
        }
      }
      requestAnimationFrame(tocBuilder)
    }

    const onAnchorScroll = element => {
      document.querySelector(`#${ element }`).scrollIntoView({behavior: "smooth"})
    }

    const tocScrollVisible = ref(false)
    const checkAndSetTocHeight = () => {
      if (window.matchMedia('(min-width: 1600px)').matches) {
        const tocHeight = document.querySelector('.c-content-toc__nav-wrapper') && document.querySelector('.c-content-toc__nav-wrapper').clientHeight || 0
        const windowHeight = window.innerHeight
        const windowHeightOffset = 148

        tocScrollVisible.value = tocHeight > (windowHeight - windowHeightOffset)
      }
    }

    const checkTime = sectionId => {
      currentSection = sectionId
      let tocItem = toc.value.find(item => { return item.id === sectionId })
      
      if (tocItem.completed === false) {
        setTimeout( () => {
          if (currentSection == sectionId) {
            tocItem.completed = true
            markComplete(sectionId, articleSlug)
          }
        }, SECTION_TIME_THRESHOLD * 1000)
      }
    }

    const intersectionCallback = entry => {
      let sectionId = ''
      
      if (entry[0].target.classList.contains('c-content-renderer__element--section')) {
        sectionId = entry[0].target.querySelector('h2').id
      } else {
        sectionId = entry[0].target.id
      }

      if (entry[0].isIntersecting === true) {
        document.querySelectorAll('.c-content-toc__nav-link').forEach(element => {
          element.classList.remove('c-content-toc__nav-link--current')
        })
        const currentTocElement = document.querySelector(`.c-content-toc__nav-link[data-toc-id="${ sectionId }"]`)
        currentTocElement.classList.add('c-content-toc__nav-link--current')

        checkTime(sectionId)
      }
    }

    const tocScrollHideBtn = ref('top')
    const moveTocScroll = ref(direction => {   
      const tocNavWrapper = document.querySelector('.c-content-toc__nav') 
      let tocScrollPosition = tocNavWrapper.scrollTop
      tocScrollHideBtn.value = ''

      if (direction === 'up') {
        if (tocNavWrapper.scrollTop >= TOC_SCROLL_OFFSET) {
          tocScrollPosition = tocScrollPosition - TOC_SCROLL_OFFSET
        } else {
          tocScrollPosition = 0
          tocScrollHideBtn.value = 'top'
        }
      }

      if (direction === 'down') {
        if (tocNavWrapper.scrollTop <= (tocNavWrapper.scrollHeight - tocNavWrapper.clientHeight - TOC_SCROLL_OFFSET)) {
          tocScrollPosition = tocScrollPosition + TOC_SCROLL_OFFSET
        } else {
          tocScrollPosition = tocNavWrapper.scrollHeight - tocNavWrapper.clientHeight
          tocScrollHideBtn.value = 'bottom'
        }
      }

      tocNavWrapper.scroll({
        top: tocScrollPosition,
        left: 0, 
        behavior: 'smooth'
      })
    })

    const showScrollBtnUp = computed(() => {
      return tocScrollVisible.value && (tocScrollHideBtn.value !== 'top')
    })

    const showScrollBtnDown = computed(() => {
      return tocScrollVisible.value && (tocScrollHideBtn.value !== 'bottom')
    })

    const tocPositionFixed = ref(true)
    const checkAndSetFooterPosition  = () => {
      const footerElement = document.querySelector('.c-footer')
      const boundingBox = footerElement.getBoundingClientRect()
      const tocElement = document.querySelector('.c-content-toc')

      if (tocScrollVisible.value === true) {
        const marginTop = 0 - Math.floor(window.innerHeight - boundingBox.y)
        if (marginTop < 0) {
          tocElement.style.marginTop = `${ marginTop }px`
          tocPositionFixed.value = false
        } else {
          tocElement.style.marginTop = 0
          tocPositionFixed.value = true
        }
      }
    }

    const onResize = () => {
      requestAnimationFrame(() => {
        checkAndSetTocHeight()
        checkAndSetFooterPosition()
      })
    }

    const onScroll = () => {
      requestAnimationFrame(() => {
        checkAndSetFooterPosition()
      })
    }

    const tocLength = computed(() => {
      return toc.value.length
    })

    watch(tocLength, () => {
      onResize()

      const observerOptions = {
        root: document,
        rootMargin: '0px',
        threshold: 0,
      }

      toc.value.forEach( tocItem => {
        const tocObserver = new IntersectionObserver(intersectionCallback, observerOptions)
        const sectionElement = document.querySelector(`#${ tocItem.id }`).closest('.c-content-renderer__element--section')

        if (sectionElement) {
          tocObserver.observe(sectionElement)
        } else {
          tocObserver.observe(document.querySelector(`#${ tocItem.id }`))
        }
      })
    })

    onMounted(() => {
      requestAnimationFrame(tocBuilder)
      onResize()
      window.addEventListener('resize', onResize)
      window.addEventListener('scroll', onScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', onScroll)
    })

    return {
      toc,
      tocScrollVisible,
      tocPositionFixed,
      moveTocScroll,
      showScrollBtnUp,
      showScrollBtnDown,
      onAnchorScroll,
    }
  }
}
</script>
