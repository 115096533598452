<template>
  <div class="c-related-resources">
    <div class="c-related-resources__wrapper">
      <TheHeading
        v-if="!isGrouped"
        level="h3"
        class="c-related-resources__title"
      >
        {{ name }}
      </TheHeading>
      <div
        v-for="group in groupedResources"
        :key="group.title"
        class="c-related-resources__group"
        :class="[`c-related-resources__group--${group.type}`]"
      >
        <div class="c-related-resources__group-wrapper">
          <TheHeading
            v-if="isGrouped"
            level="h3"
            class="c-related-resources__title"
          >
            {{ group.title }}
          </TheHeading>
          <div class="c-related-resources__items">
            <div
              v-for="item in group.items"
              :key="item.value"
              class="c-related-resources__item"
            >
              <component :is="getComponentName(group.type)" :data="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import TheHeading from '@/components/TheHeading'
import ResourcesConcept from '@/components/RelatedResourceCollection/Concept'
import ResourcesFiles from '@/components/RelatedResourceCollection/Files'
import ResourcesLink from '@/components/RelatedResourceCollection/Link'
import ResourcesVideo from '@/components/RelatedResourceCollection/Video'
import ResourcesArticle from '@/components/RelatedResourceCollection/Article'
import ResourcesText from '@/components/RelatedResourceCollection/Text'

export default {
  name: 'RelatedResourcesCollection',

  components: {
    TheHeading,
    ResourcesConcept,
    ResourcesFiles,
    ResourcesLink,
    ResourcesVideo,
    ResourcesArticle,
    ResourcesText,
  },

  props: {
    data: Object,
    name: String,
  },

  setup(props) {
    const { t } = useI18n()
    const resources = computed(() => props.data.content.resources)
    const allowedTypes = ['concept', 'files', 'link', 'video', 'article', 'text']
    const isGrouped = computed(
      () => props.name === 'related-resource-collection',
    )
    const groupedResources = computed(() => {
      const groups = []
      if (isGrouped.value) {
        allowedTypes.forEach((at) => {
          const obj = {}

          obj.title = t(`RRC_${at.toUpperCase()}`)
          obj.type = at
          obj.items = resources.value.filter(
            (resource) => resource.type === obj.type,
          )
          if (obj.items.length) {
            groups.push(obj)
          }
        })
      } else {
        let obj

        resources.value.forEach((resource, index) => {
          if (obj && obj.type === resource.type) {
            obj.items.push(resource)
          } else if (allowedTypes.includes(resource.type)) {
            if (obj) {
              groups.push(obj)
            }
            obj = {
              type: resource.type,
              title: t(`RRC_${resource.type.toUpperCase()}`),
              items: [resource],
            }
          }
          if (index === resources.value.length - 1 && obj.items.length) {
            groups.push(obj)
          }
        })
      }

      return groups
    })

    const getComponentName = (type) => {
      const compNames = {
        concept: 'ResourcesConcept',
        files: 'ResourcesFiles',
        link: 'ResourcesLink',
        video: 'ResourcesVideo',
        article: 'ResourcesArticle',
        text: 'ResourcesText'
      }

      return compNames[type]
    }

    return {
      isGrouped,
      groupedResources,
      getComponentName,
    }
  },
}
</script>
