import DB from '@/core/services/db'
import { useAuth } from '@/core'

const db = new DB()
const { user } = useAuth()

const markComplete = async (sectionId, articleSlug) => {
  try {
    await db.createOrUpdate({
      _id: `${user.value._id}-${articleSlug}-${sectionId}`,
      name: 'completed',
      value: true,
    })
  } catch(err) {
    return null
  }
}

const isCompleted = async (sectionId, articleSlug) => {
  try {
    await db.getData(`${user.value._id}-${articleSlug}-${sectionId}`)

    return true
  } catch(err) {
    return false
  }
}

export {
  markComplete,
  isCompleted,
}
