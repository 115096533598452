import { sortItemsByOrder } from '@/core/utils'
import useLocalizations from '@/core/useLocalizations'
import useStructures from '@/core/useStructures'
import useContentsLocal from '@/composables/useContents'

const { getObjectById } = useContentsLocal()
const { getLocalizationById } = useLocalizations()
const { structures } = useStructures()

const getStructuresChildrens = (parentId, type, locale = '') => {
  const items = JSON.parse(JSON.stringify(sortItemsByOrder(structures.value.filter(s => {
    if (type) {
      return s.parent === parentId && s.type === type
    }

    return s.parent === parentId
  }))))

  items.map(item => {
    if (locale) {
      item.name = getLocalizationById(item.id, locale, 'structure')?.content.name || item.name
      item.description = getLocalizationById(item.id, locale, 'structure')?.content.description || item.description
    }
    if (item.type === 'OBJECT' && item.contentId) {
      item.contentData = getObjectById(item.contentId, locale)
    }

    return item
  })

  return items
}

export default () => {
  return {
    getStructuresChildrens,
  }
}
