<template>
  <div class="c-language-select u-px">
    <div v-if="label" class="c-language-select__label">{{ label }}:</div>
    <Multiselect
      v-model="selected"
      :options="languageList"
      :can-clear="canClear"
      :can-deselect="canDeselect"
      :placeholder="placeholder"
      @change="onSelectChange"
    />
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default defineComponent({
  name: 'LanguageSelect',

  components: {
    Multiselect,
  },

  props: {
    languageList: Object,
    currentLanguage: String,
    canClear: Boolean,
    canDeselect: Boolean,
    placeholder: String,
    label: String,
  },

  setup(props, { emit }) {
    const selected = ref('')
    onBeforeMount(() => {
      selected.value = props.currentLanguage
    })
    const onSelectChange = (event) => {
      emit('languageChange', event)
    }

    watch(
      () => props.currentLanguage,
      (parentLang) => {
        if (selected.value !== parentLang) {
          selected.value = parentLang
        }
      },
    )

    return {
      selected,
      onSelectChange,
    }
  },
})
</script>
