import { markdownToHtml } from '@/core/utils'
import katex from 'katex'
import mediumZoom from 'medium-zoom'
import { transformTables } from '@/core/utils/tables'
import { supportCheckList } from '@/core/utils/checklist'

const renderKatex = el => {
  Array.from(el.querySelectorAll('.katex')).forEach(k => {
    k.innerHTML = katex.renderToString(k.innerHTML, {
      throwOnError: false,
    })
  })
}

const mdHtml = {
  beforeMount: function(el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
    renderKatex(el)
    transformTables(el)
    el.querySelectorAll('img').forEach(imgTag => {
      if (!imgTag.classList.contains('nozoom')){
        mediumZoom(imgTag)
      }
    })

    supportCheckList(el)
  },

  beforeUpdate: function(el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
    renderKatex(el)
    transformTables(el)
  },
}

export default mdHtml
