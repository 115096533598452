<template>
  <div class="c-topbar" :class="{ 'c-topbar--fixed': isNavMobileOpened }">
    <div class="c-topbar__wrapper">
      <div class="c-topbar__left">
        <router-link
          to="/"
          class="c-topbar__logo"
          @click="isNavMobileOpened = false"
        >
          <img
            :src="logoSrc"
            :alt="$t('TOPBAR_LOGO_ALT')"
            class="c-topbar__logo-img"
          />
        </router-link>
      </div>
      <div class="c-topbar__right">
        <DBokMenu v-if="dBokLink" :dbok="dBokLink" />
        <LanguageSelect
          v-if="isLanguageSwitch && user && langLoaded"
          :language-list="languageList"
          :currentLanguage="locale"
          :canClear="false"
          :canDeselect="false"
          @languageChange="onLanguageChange"
        />
        <div v-if="user" class="c-topbar__logout">
          <TheButton @click="logout" text class="c-topbar__logout-btn">
            <span class="c-topbar__logout-text">{{ $t('TOPBAR_LOGOUT') }}</span>
          </TheButton>
        </div>
      </div>
      <Hamburger @click="toggleNavMobile" :isOpened="isNavMobileOpened" />
    </div>
    <NavMobile
      :isUserLogged="user ? true : false"
      :isOpened="isNavMobileOpened"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAuth, useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton'
import LanguageSelect from '@/components/LanguageSelect'
import NavMobile from '@/components/NavMobile'
import Hamburger from './Hamburger.vue'
import DBokMenu from '@/components/DBokMenu'
import { getLanguage, setLanguage } from '@/services/languageSelect'

export default defineComponent({
  name: 'Topbar',

  components: {
    TheButton,
    LanguageSelect,
    NavMobile,
    Hamburger,
    DBokMenu,
  },

  setup() {
    const { tenant } = useTenant()
    const { logout, user } = useAuth()
    const { locale } = useI18n()
    const dBokLink = computed(() =>
      tenant.value.dbok ? tenant.value.dbok[locale.value] : false,
    )

    const logoSrc = computed(() => tenant.value.assets.logo)

    const isLanguageSwitch = computed(() => {
      if (tenant.value.langs.length > 1) {
        return true
      }
      return false
    })

    const languageList = computed(() =>
      tenant.value.langs.map((lang) => {
        return {
          value: lang.code,
          label: lang.label,
        }
      }),
    )
    const langLoaded = ref(null)

    const onLanguageChange = async (lang) => {
      await setLanguage(lang)
      locale.value = lang
      location.reload()
    }

    const isNavMobileOpened = ref(false)
    const toggleNavMobile = () => {
      isNavMobileOpened.value = !isNavMobileOpened.value
      document.documentElement.style.overflow = isNavMobileOpened.value
        ? 'hidden'
        : 'auto'
      window.scrollTo(0, 0)
    }

    const route = useRoute()

    onMounted(async () => {
      locale.value = (await getLanguage()) || 'nb'
      langLoaded.value = true
    })
    watch(
      () => route.path,
      () => {
        isNavMobileOpened.value = false
        document.documentElement.style.overflow = 'auto'
      },
    )

    return {
      logout,
      logoSrc,
      isLanguageSwitch,
      isNavMobileOpened,
      toggleNavMobile,
      user,
      languageList,
      onLanguageChange,
      langLoaded,
      locale,
      dBokLink,
    }
  },
})
</script>
