<template>
  <Topbar />
  <router-view :key="$route.path" />
  <Modal />
  <Footer />
</template>

<script>
import { getCurrentInstance, onBeforeMount } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'

import { useTenant } from '@/core'

import Topbar from '@/components/Topbar'
import Modal from '@/components/Modal'
import Footer from '@/components/Footer'

export default {
  components: {
    Topbar,
    Modal,
    Footer,
  },

  props: {
    tenantConfig: Object,
  },

  setup(props) {
    const { initTenant, tenant } = useTenant()
    const internalInstance = getCurrentInstance()

    onBeforeMount(() => {
      initTenant(props.tenantConfig)

      if (tenant.value.analytics && tenant.value.analytics.gtm) {
        internalInstance.appContext.app.use(
          createGtm({
            id: tenant.value.analytics.gtm,
          }),
        )
      }
    })
  },
}
</script>
