<template>
  <div class="c-audio">
    <TheButton
      v-if="audioPlayed === audio && playPlayer"
      text
      @click="pauseAudio()"
      class="c-audio__pause"
    />
    <TheButton v-else text @click="playAudio(audio)" class="c-audio__play" />
  </div>
</template>

<script>
import { computed, onBeforeUnmount } from 'vue'
import TheButton from '@/components/TheButton'
import useAudio from '@/composables/useAudio'

export default {
  name: 'Audio',

  components: {
    TheButton,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const audio = computed(() => props.data?.content?.audioId)
    const { onPlayAudio, onPauseAudio, audioPlayed, playPlayer } = useAudio()

    const playAudio = (audioId) => {
      onPlayAudio(audioId)
    }

    const pauseAudio = () => {
      onPauseAudio()
    }

    onBeforeUnmount(() => {
      onPauseAudio()
    })

    return {
      audio,
      playAudio,
      pauseAudio,
      audioPlayed,
      playPlayer,
    }
  },
}
</script>
