import { ref } from 'vue'

const BREADCRUMBS_VALUE = []
const breadcrumbs = ref(BREADCRUMBS_VALUE)
const loadingBreadcrumbs = ref(false)
const clearBreadcrumbs = () => {
  breadcrumbs.value = BREADCRUMBS_VALUE
  loadingBreadcrumbs.value = true
}
const setBreadcrumbs = bc => {
  breadcrumbs.value = bc
  loadingBreadcrumbs.value = false
}

export default () => {
  return {
    breadcrumbs,
    clearBreadcrumbs,
    setBreadcrumbs
  }
}
