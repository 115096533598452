const reorderTable = (table) => {
  let innerHtml = ''

  if (
    table.classList.contains('stack') &&
    table.classList.contains('headers-left')
  ) {
    table.querySelectorAll('th, td').forEach((cell) => {
      innerHtml += `<tr>${cell.outerHTML}</tr>`
    })
  } else if (
    table.classList.contains('stack') &&
    table.classList.contains('classic')
  ) {
    const originalTableHTML = []
    table.querySelectorAll('tr').forEach((tr) => {
      originalTableHTML.push(tr.querySelectorAll('th, td'))
    })

    for (const index of originalTableHTML[0].keys()) {
      originalTableHTML.forEach((trContent) => {
        innerHtml += `<tr>${
          trContent[index] ? trContent[index].outerHTML : ''
        }</tr>`
      })
    }
  } else if (
    table.classList.contains('stack') &&
    table.classList.contains('transposed')
  ) {
    const originalTableHTML = []
    const topHeaders = []
    const leftHeaders = []
    const restContent = []

    table.querySelectorAll('tr').forEach((tr, trIndex) => {
      if (trIndex === 0) {
        topHeaders.push(...tr.querySelectorAll('th'))
      } else {
        const rowsData = tr.querySelectorAll('th, td')
        const restRow = []
        rowsData.forEach((cell, tdIndex) => {
          if (tdIndex === 0) {
            leftHeaders.push(cell)
          } else {
            restRow.push(cell)
          }
        })
        restContent.push(restRow)
      }
      originalTableHTML.push()
    })

    let transposed = ''
    restContent.forEach((row, rowIndex) => {
      transposed += `<tr>${topHeaders[0].outerHTML}${leftHeaders[rowIndex].outerHTML}</tr>`
      row.forEach((cell, cellIndex) => {
        transposed += `<tr>${topHeaders[cellIndex + 1].outerHTML}${
          cell.outerHTML
        }</tr>`
      })
    })
    innerHtml = transposed
  } else if (
    table.classList.contains('stack') &&
    table.classList.contains('balance')
  ) {
    const originalTableHTML = []
    table.querySelectorAll('tr').forEach((tr) => {
      originalTableHTML.push(tr.querySelectorAll('th, td'))
    })

    for (const [rowIndex, row] of originalTableHTML.entries()) {
      let trPart = '<tr>'
      for (const index of row.keys()) {
        if ((index < 2 && rowIndex !== 1) || (index === 0 && rowIndex === 1)) {
          trPart += `${row[index].outerHTML.replace(
            'colspan="4"',
            'colspan="2"',
          )}`
        }
      }
      trPart += '</tr>'
      if (rowIndex === originalTableHTML.length - 1) {
        trPart = trPart.replace('<tr>', '<tr class="summary">')
      }
      innerHtml += trPart
    }
    for (const [rowIndex, row] of originalTableHTML.entries()) {
      let trPart = '<tr>'
      for (const index of row.keys()) {
        if (index >= 2 || (index === 1 && rowIndex === 1)) {
          trPart += `${row[index].outerHTML}`
        }
      }
      trPart += '</tr>'
      if (rowIndex === originalTableHTML.length - 1) {
        trPart = trPart.replace('<tr>', '<tr class="summary">')
      }
      innerHtml += trPart
    }
  } else {
    innerHtml = table.innerHTML
  }
  return innerHtml
}

const transformTables = (el) => {
  const tables = el.querySelectorAll('table')
  tables.forEach((table) => {
    const mobileTable = document.createElement('table')
    const mobileTableHtml = reorderTable(table)
    table.classList.forEach((className) => {
      mobileTable.classList.add(`${className}-mobile`)
    })
    table.classList.add('c-table-lg')
    mobileTable.innerHTML = mobileTableHtml
    const container = document.createElement('div')
    container.classList.add('c-mobile-table-wrapper')
    container.append(mobileTable)
    table.after(container)
  })
}

export { reorderTable, transformTables }
