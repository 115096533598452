<template>
  <TheButton class="c-hamburger" :class="{ 'c-hamburger--open': isOpened }">
    <div class="c-hamburger__icon">
      <span class="c-hamburger-line"/>
      <span class="c-hamburger-line"/>
      <span class="c-hamburger-line"/>
    </div>
    <div class="c-hamburger__text">
      {{ $t('HAMBURGER_MENU') }}
    </div>
  </TheButton>
</template>

<script>
import TheButton from '@/components/TheButton'

export default {
  name: 'Hamburger',

  props: {
    isOpened: Boolean,
  },

  components: {
    TheButton,
  },
}
</script>