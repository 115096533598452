<template>
  <div class="c-content-renderer">
    <TheHeading v-if="title" class="c-content-renderer__title">{{
      title
    }}</TheHeading>
    <div v-if="contentItems.length" class="c-content-renderer__wrapper">
      <ContentToC v-if="hasToc" parent-class="c-content-renderer" />
      <div
        class="c-content-renderer__element"
        :class="[`c-content-renderer__element--${item.contentType}`]"
        v-for="item in contentItems"
        :key="item.id"
        >
        <component
          v-if="item.contentData"
          :is="mapComponent(item.contentType)"
          :data="item.contentData"
          :name="item.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheHeading from '@/components/TheHeading'
import ContentToC from '@/components/ContentToC'
import ElementRelatedResourceCollection from '@/components/Elements/related-resource-collection'
import ElementText from '@/components/Elements/text'
import ElementImage from '@/components/Elements/image'
import ElementFile from '@/components/Elements/file'
import ElementH5P from '@/components/Elements/h5p'
import ElementAudio from '@/components/Elements/audio'
import ElementVideo from '@/components/Elements/video'
import ElementSection from '@/components/Elements/section'

export default {
  name: 'ContentRenderer',

  props: {
    title: String,
    contentItems: Array,
    hasToc: Boolean,
  },

  components: {
    ContentToC,
    TheHeading,
    ElementRelatedResourceCollection,
    ElementText,
    ElementImage,
    ElementH5P,
    ElementFile,
    ElementVideo,
    ElementAudio,
    ElementSection,
  },

  setup() {
    const mapComponent = (type) => {
      const types = {
        audio: 'ElementAudio',
        video: 'ElementVideo',
        image: 'ElementImage',
        text: 'ElementText',
        h5p: 'ElementH5P',
        file: 'ElementFile',
        'related-resource-collection': 'ElementRelatedResourceCollection',
        section: 'ElementSection',
      }

      return types[type]
    }

    return {
      mapComponent,
    }
  },
}
</script>
