<template>
  <nav class="c-nav-mobile" :class="{ 'c-nav-mobile--open': isOpened }">
    <ul class="c-nav-mobile__list">
      <NavList v-if="isUserLogged" :items="sections" />
      <li class="c-nav__item">
          <TheButton
            :href="tenant.docToolUrl || 'https://dokumentasjon.fagbokforlaget.no'"
            target="_blank"
            class="c-nav__link c-button"
          >
            {{ $t('NAV_DOCTOOL') }}
          </TheButton>
      </li>
      <DBokMenu v-if="dBokLink" :dbok="dBokLink" />
      <LanguageSelect
        v-if="isLanguageSwitch && user && langLoaded"
        :language-list="languageList"
        :currentLanguage="locale"
        :canClear="false"
        :canDeselect="false"
        @languageChange="onLanguageChange"
      />
      <li v-if="isUserLogged" class="c-nav__item">
        <TheButton @click="logout" text class="c-topbar__logout-btn">{{
          $t('TOPBAR_LOGOUT')
        }}</TheButton>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useAuth, useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import NavList from '@/components/NavList'
import DBokMenu from '@/components/DBokMenu'
import LanguageSelect from '@/components/LanguageSelect'
import TheButton from '@/components/TheButton'
import { getLanguage, setLanguage } from '@/services/languageSelect'

export default {
  name: 'NavMobile',

  components: {
    NavList,
    DBokMenu,
    LanguageSelect,
    TheButton,
  },

  props: {
    isOpened: Boolean,
    isUserLogged: Boolean,
  },

  setup() {
    const { tenant } = useTenant()
    const { locale } = useI18n()
    const { logout, user } = useAuth()

    const sections = computed(() => tenant.value.mainNav[locale.value] || [])
    const dBokLink = computed(() =>
      tenant.value.dbok ? tenant.value.dbok[locale.value] : false,
    )
    const isLanguageSwitch = computed(() => tenant.value.langs.length > 1)
    const languageList = computed(() =>
      tenant.value.langs.map((lang) => {
        return {
          value: lang.code,
          label: lang.label,
        }
      }),
    )
    const langLoaded = ref(null)
    const onLanguageChange = async (lang) => {
      await setLanguage(lang)
      locale.value = lang
      location.reload()
    }

    onMounted(async () => {
      locale.value = (await getLanguage()) || 'nb'
      langLoaded.value = true
    })

    return {
      sections,
      dBokLink,
      isLanguageSwitch,
      logout,
      user,
      langLoaded,
      languageList,
      locale,
      onLanguageChange,
      tenant
    }
  },
}
</script>

<style lang="scss"></style>
