import { ref, computed } from 'vue'
import useStructuresLocal from '@/composables/useStructures'
import i18n from '@/i18n'

const { getStructuresChildrens } = useStructuresLocal()
const isModalOpen = ref(false)
const modalComponent = ref('')
const modalContent = ref(null)
const modalContentItems = computed(() => {
  return getStructuresChildrens(
    modalContent.value.id,
    'OBJECT',
    i18n.global.locale,
  )
})
const onEsc = (ev) => {
  if (ev.key === 'Escape') {
    onClose()
  }
}
const onClose = () => {
  isModalOpen.value = false
  modalComponent.value = ''
  document.documentElement.style.overflow = 'auto'
  window.removeEventListener('keydown', onEsc)
}
const setModal = (component, content) => {
  isModalOpen.value = true
  modalComponent.value = component
  modalContent.value = content
  document.documentElement.style.overflow = 'hidden'
  window.addEventListener('keydown', onEsc)
}
const closeModal = () => {
  onClose()
}

export default () => {
  return {
    isModalOpen,
    modalComponent,
    modalContent,
    modalContentItems,
    setModal,
    closeModal,
  }
}
