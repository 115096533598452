<template>
  <div class="c-text">
    <div v-if="data && data.content" class="c-text__content" v-md-html="data.content.content" :data-element-id="data.id" />
  </div>
</template>

<script>
export default {
  name: 'Text',

  props: {
    data: Object
  }
}
</script>
