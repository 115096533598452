import { fetchSH } from '../api'
import { buildQuery } from '../utils'

const endpoint = 'contents'

/* Content Objects CRUD */
const getObjectsAll = (namespace, query) => fetchSH(`${endpoint}/${namespace}/objects${buildQuery(query)}`)
const getObject = (namespace, id) => fetchSH(`${endpoint}/${namespace}/objects/${id}`)

export {
  getObjectsAll,
  getObject
}
