<template>
  <component
    :is="currentComponent"
    class="c-button"
    :class="[`c-button--icon-${icon}`, `c-button--${currentComponent}`, {'c-button--text': text}]"
    v-bind="attributes"
  >
    <slot>
    </slot>
  </component>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'TheButton',

  props: {
    to: String,
    href: String,
    icon: {
      type: String,
      default: 'none'
    },
    text: Boolean
  },

  setup(props) {
    const currentComponent = computed(() => {
      if (props.to) {
        return 'router-link'
      } else if (props.href) {
        return 'a'
      } else {
        return 'button'
      }
    })
    const attributes = computed(() => {
      if (currentComponent.value === 'router-link') {
        return {
          to: props.to
        }
      } else if (currentComponent.value === 'a') {
        return {
          href: props.href,
          target: '_blank'
        }
      } else {
        return {}
      }
    })

    return {
      attributes,
      currentComponent
    }
  }
}
</script>
