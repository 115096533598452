<template>
  <div
    class="c-rrc-text"
  >
    <TheHeading level="h3" class="c-rrc-text__title">
      {{ data.title }}
    </TheHeading>
    <div class="c-rrc-text__desc">
      {{ data.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RRCText',

  props: {
    data: Object,
  },
}
</script>