<template>
  <FHFooter :hide-social="true" class="c-footer"/>
</template>

<script>
import FHFooter from '@forlagshuset/v-small-footer'

export default {
  components: {
    FHFooter
  }
}
</script>