import { fetchSH } from '../api'
import { buildQuery } from '../utils'

const endpoint = 'i18n'

// Contents i18n
const geti18nContentsAll = (query) => fetchSH(`${endpoint}/contents${buildQuery(query)}`)
const geti18nContents = (id, locale) => fetchSH(`${endpoint}/contents/${id}/${locale}`)

// Structures i18n
const geti18nStructuresAll = (query) => fetchSH(`${endpoint}/structures${buildQuery(query)}`)
const geti18nStructures = (id, locale) => fetchSH(`${endpoint}/structures/${id}/${locale}`)

export {
  geti18nContentsAll,
  geti18nContents,
  geti18nStructuresAll,
  geti18nStructures
}
