<template>
  <div class="c-section">
    <ContentRenderer v-if="data.length" :content-items="data" />
  </div>
</template>

<script>
export default {
  name: 'Section',

  props: {
    data: Object
  },
}
</script>
