import { createRouter, createWebHistory } from 'vue-router'
import { beforeEach } from '@/core/router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/concepts/:conceptsParent',
    name: 'ConceptsSearch',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/ConceptsSearch.vue')
  },
  {
    path: '/lookup/:lookupParent',
    name: 'Lookup',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Lookup.vue')
  },
  {
    path: '/articles/:parent',
    name: 'Articles',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Articles.vue')
  },
  {
    path: '/articles/:content*',
    name: 'SingleArticle',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/SingleContent')
  },
  {
    path: '/files/:parent',
    name: 'Files',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Files.vue')
  },
  {
    path: '/media/:parent',
    name: 'Media',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Media.vue')
  },
  {
    path: '/media/:content*',
    name: 'SingleMedia',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/SingleContent')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach(beforeEach)

export default router
