import { ref, computed } from 'vue'
import { useHead } from '@vueuse/head'

const currentLang = 'nb' // take from i18n settings

const tenant = ref(null)
const mainNav = computed(() => {
  return tenant.value && tenant.value.mainNav && tenant.value.mainNav[currentLang] || []
})
const initTenant = config => {
  tenant.value = config
  document.querySelector('body').classList.add(tenant.value.slug)
  setHead()
}
const setHead = () => {
  const links = () => {
    const arr = [
      {
        rel: 'icon',
        href: tenant.value.assets.favicon
      }
    ]
    tenant.value.styles.forEach(s => {
      arr.push({
        rel: 'stylesheet',
        href: s
      })
    })

    return arr
  }
  useHead({
    link: links(),
    meta: [
      {
        name: `description`,
        content: tenant.value.description || 'Edtech app.',
      },
    ],
  })
}

export default () => {
  return {
    tenant,
    mainNav,
    initTenant
  }
}
