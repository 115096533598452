import DB from '@/core/services/db'
import { useAuth, useTenant } from '@/core'

const db = new DB()
const { instance } = useAuth()
const { tenant } = useTenant()

const getConceptsLanguage = async () => {
  try {
    const lang = await db.getData(
      `${tenant.value.namespace}-${instance().getUser()._id}-concepts-language`,
    )

    return lang.value
  } catch {
    return ''
  }
}

const setConceptsLanguage = async (lang) => {
  try {
    await db.createOrUpdate({
      _id: `${tenant.value.namespace}-${instance().getUser()._id}-concepts-language`,
      name: 'conceptsLanguage',
      value: lang,
    })
  } catch {
    // silence
  }
}

const getLanguage = async () => {
  try {
    const lang = await db.getData(
      `${tenant.value.namespace}-${instance().getUser()._id}-language`,
    )

    return lang.value
  } catch (err) {
    return false
  }
}

const setLanguage = async (lang) => {
  try {
    await db.createOrUpdate({
      _id: `${tenant.value.namespace}-${instance().getUser()._id}-language`,
      name: 'language',
      value: lang,
    })
  } catch (err) {
    return null
  }
}

export { getConceptsLanguage, setConceptsLanguage, getLanguage, setLanguage }
