<template>
  <div v-if="data && data.content.resourceId" class="c-h5p__container">
    <VH5P
      :id="data.content.resourceId"
      tabindex="0"
      width=1088
      height=755
    >
      <template #instructions>
        <div v-if="data.content.instructions" class="c-h5p__instructions" v-md-html="data.content.instructions" />
      </template>
    </VH5P>
  </div>
</template>

<script>
import VH5P from '@forlagshuset/v-h5p'

export default {
  name: 'H5PWrapper',

  components: {
    VH5P
  },

  props: {
    data: Object
  }
}
</script>
