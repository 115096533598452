<template>
  <a
    class="c-rrc-link"
    :href="link"
    target="_blank"
    rel="noreferrer noopener"
  >
    <TheHeading level="h3" class="c-rrc-link__title">
      {{ title }}
    </TheHeading>
    <div class="c-rrc-link__text">
      {{ link }}
    </div>
  </a>
</template>

<script>
import { computed } from 'vue'
import TheHeading from '@/components/TheHeading'

export default {
  components: {
    TheHeading
  },

  props: {
    data: Object
  },

  setup(props) {
    const title = computed(() => props.data.title)
    const link = computed(() => props.data.value)

    return {
      title,
      link
    }
  },
}
</script>