<template>
  <li
    v-for="item in items"
    :key="item.to"
    class="c-nav__item"
    @click="isOpened = false"
  >
    <TheButton
      v-if="showProduct(item)"
      :to="item.to"
      :href="item.href"
      class="c-nav__link"
      :class="[
        `c-nav-link--${getAccessLevel(item.accessLevel)}`,
        `c-nav-link--${item.class}`,
      ]"
    >
      {{ item.title }}
    </TheButton>
    <TheButton
      v-else
      @click="showModal"
      class="c-nav__link c-nav-link--locked"
      :class="`c-nav-link--${item.class}`"
    >
      {{ item.title }}
    </TheButton>
  </li>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useAuth, useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import useModal from '@/composables/useModal'
import TheButton from '@/components/TheButton'

export default {
  components: {
    TheButton,
  },

  props: {
    items: Array,
  },

  setup() {
    const auth = useAuth()
    const isOpened = ref(false)
    const { tenant } = useTenant()
    const availableProducts = ref([])
    const { setModal } = useModal()
    const { t } = useI18n()

    const getAccessLevel = (al) => {
      return (al && al.toLowerCase()) || 'student'
    }

    const showProduct = (item) => {
      if (!item || !item.productAccess) {
        return true
      }
      return availableProducts.value.some((p) => item.productAccess.includes(p))
    }

    const showModal = () => {
      setModal('ModalText', {
        title: 'modal name',
        content: {
          name: t('NAV_NO_ACCESS'),
          text: t('NAV_NO_ACCESS_DESCRIPTION'),
        },
      })
    }

    onMounted(async () => {
      const epProducts = tenant.value.eportalProducts
      if (epProducts && epProducts.length) {
        const { products } = await auth
          .instance()
          .checkAccess(epProducts.map((ep) => ep.id))
        availableProducts.value = epProducts
          .filter((p) => products.map((ep) => ep.id).includes(p.id))
          .map(({ product }) => product)
      }
    })

    return {
      isOpened,
      getAccessLevel,
      showProduct,
      showModal,
    }
  },
}
</script>
