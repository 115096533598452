<template>
  <div 
    v-if="isModalOpen" 
    class="c-modal" 
    :class="`c-modal c-${modalComponent.toLowerCase()}`"
    @click.self="closeModal"
  >
    <component :is="modalComponent" />
  </div>
</template>

<script>
import useModal from '@/composables/useModal'
import ModalConcept from '@/components/ModalConcept.vue'
import ModalText from '@/components/ModalText.vue'

export default {
  components: {
    ModalConcept,
    ModalText,
  },

  setup() {
    const { isModalOpen, modalComponent, closeModal } = useModal()

    return {
      isModalOpen,
      closeModal,
      modalComponent,
    }
  },
}
</script>
